import React, { Component } from 'react'
import Cookies from 'js-cookie'
import Button from '../Button'
import styles from './CookieConsent.module.css'
import Row from '../Layout/Row'

export default class CookieConsent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isVisible: false,
      styles: ''
    }

    this.handleConsent = this.handleConsent.bind(this)
  }

  handleConsent(e) {
    Cookies.set('gdpr_consent', 'true')

    this.setState({
      styles: styles.hideConsent,
      // isVisible: false
    })

    setTimeout(() => this.setState({ isVisible: false }), 200)
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      if (Cookies.get('gdpr_consent')) {
        // Has cookie
        // ;
      } else {
        this.setState({ isVisible: true })
      }
    }
  }

  render() {
    return (
      <div className={`${styles.cookieConsent} ${this.state.styles}`} style={{ display: this.state.isVisible ? 'block' : 'none' }}>
        <Row rowMobile>
          <div style={{ flex: 3, lineHeight: '1.3', paddingRight: '5px' }}>
            Pre lepšie používanie našej webovej stránky používame súbory “cookies”.
            Pomocou nich analyzujeme návštevnosť {/*, prispôsobujeme reklamu */} a vaše používateľské nastavenia
            Súhlasíte so spracovaním súvisiacich osobných údajov? <a
              style={{ margin: '10px 0', fontSize: '12px' }}
              href='https://fisherio.com/privacy-policy'>
              Viac informácií o cookies</a>
          </div>
          <div style={{ flex: 1, alignSelf: 'center', paddingLeft: '5px' }}>
            <Button onClick={this.handleConsent} size={'small'} style={{ display: 'block', marginLeft: 'auto', padding: '20px 16px' }}>Súhlasím</Button>
          </div>
        </Row>
      </div>
    )
  }
}
