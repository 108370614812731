import React from "react"
import Section from "../../components/Section"
import Heading from "../../components/Typography/Heading"
import Text from "../../components/Typography/Text"
import Divider from "../../components/Divider"
import Footer from "../../components/Footer"
import Container from "../../components/Layout/Container"
import StoreButtons from "../../components/StoreButtons"
import CookieConsent from "../../components/CookieConsent"
import SEO from "../../components/seo"
import HitlistPresentation from "../../components/HitlistPresentation/HitlistPresentation"
import RHeaderImage from "../../components/RHeaderImage"

import VegezemlaPng from "../../images/r/vegezemla.jpg"

const Vegezemla = () => (
  <Container wide>
    <SEO title={"Hitlist | Pomocník do vrecka pri histamínovej intolerancii"} />
    
    <RHeaderImage
      src={VegezemlaPng}
    />

    <Container>
      <main>
        <Divider spaceOnly small />
        <Section
          title={
            <Heading centered headingSize={1}>
              Vegežemla
            </Heading>
          }
          body={
            <>
              <Text white widerLineHeight>
                Pripravte si jednoducho a rýchlo chutnú Vege žemľu! 🍔 😋 <br />
                <br />
                Budete potrebovať:
                <br />
                🥒 Na kolieska nakrájanú šalátovú uhorku <br />
                🥬 Šalátové listy nakrajané na pásiky a poliate olivovým olejom{" "}
                <br />
                🌶 Kápiu alebo papriku nakrájanú na kolieska <br />
                🧈 Rastlinné maslo (napríklad Alsan alebo Flora) <br />
                🧀 Vegánsky syr (my sme použili Violife Block, dá sa kúpiť v
                Tesco) <br />
                🥖 Žemľa alebo iné pečivo (my sme použili žemle Tesco Hamburgers
                buns, ktoré neobsahujú mlieko)
                <br /> <br />
                Jednotlivé suroviny si môžete prispôsobiť podľa vašej osobnej
                tolerancie, hlavne nech vám chutí! 😉 <br />
                Dobrú chuť 😊
              </Text>
            </>
          }
        />

        <HitlistPresentation />
        <Divider spaceOnly />
        <Divider spaceOnly />
        <Footer />
      </main>
    </Container>
  </Container>
)

export default Vegezemla
