import React from 'react'
import styles from './button.module.css'

function Button(props) {
  const { white, children, ...rest } = props

  const medium = rest.size === 'medium' ? styles.medium : ''
  const large = rest.size === 'large' ? styles.large : ''
  const whiteClass = white ? styles.white : '' 
  const style = `${styles.button} ${medium} ${large} ${whiteClass}`
  

  return (
    <button type="button" className={style} {...rest}>
      {children}
    </button>
  )
}

Button.propTypes = {

}

export default Button

