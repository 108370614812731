import React, { Component } from "react"
import PropTypes from "prop-types"
import styles from "./rheaderImage.module.css"
/**
 * Required image with dimensions 1200x600px
 */
export default class RHeaderImage extends Component {
  static propTypes = {
    src: PropTypes.string,
  }

  render() {
    const { src, ...rest } = this.props

    return (
      <img
        className={styles.image}
        src={src}
        alt={"Obrázok finálneho jedla. Photo of prepared food."}
        {...rest}
      />
    )
  }
}
